import React, { useEffect, useState } from "react";
import classes from "./MyWork.module.css";
import db from "./../../firebase/firebase";
import { getDocs, collection } from "firebase/firestore";
import { FaGithubSquare } from "react-icons/fa";

const MyWork = () => {
  const [projects, setProjects] = useState([]);

  const getProjects = async () => {
    const p = [];
    const querySnapshot = await getDocs(collection(db, "projects"));
    querySnapshot.forEach((doc) => {
      p.push(doc.data());
    });
    setProjects(p);
  };

  useEffect(() => {
    getProjects();
  }, []);
  return (
    <section className={classes.myWorkSection} id="myWork">
      <div className={classes.myWorkContainer}>
        <div className={classes.wording}>My Work</div>
        {projects.length === 0 ? (
          <div class={classes.loaderContainer}>
            <div class={classes.loader}></div>
          </div>
        ) : (
          <div className={classes.projectsContainer}>
            {projects.map((project) => (
              <a
                className={classes.project}
                href={`${project.link}`}
                target="_blank"
                key={project.id}
              >
                <p className={classes.title}>{project.title}</p>
                <img
                  src={`${project.pictureURL}`}
                  alt=""
                  height="180"
                  width="300"
                  className={classes.img}
                  loading="lazy"
                />
                <p className={classes.description}>{project.description}</p>
                <a
                  href={project.githubLink}
                  target="_blank"
                  className={classes.icon}
                >
                  <FaGithubSquare />
                </a>
              </a>
            ))}
          </div>
        )}
      </div>
    </section>
  );
};

export default MyWork;
