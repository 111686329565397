import React, { useEffect, useRef, useState } from "react";
import classes from "./NavBar.module.css";
import { FaBars } from "react-icons/fa";
import { Link } from "react-scroll";

const NavBar = () => {
  const [navAtTop, setNavAtTop] = useState(false);
  const [showNav, setShowNav] = useState(false);
  const [atBottom, setAtBottom] = useState(false);
  const navBar = useRef();

  const navClass = navAtTop ? `${classes.nav} ${classes.navTop}` : `${classes.nav}`;

  const handleScroll = () => {
    if (navBar.current.getBoundingClientRect().top <= 0) {
      setNavAtTop(true);
    } else {
      setNavAtTop(false);
    }
    if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
      setAtBottom(true);
    } else {
      setAtBottom(false);
    }
  };

  const navHandler = () => {
    setShowNav(!showNav);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);

  return (
    <nav className={navClass} ref={navBar}>
      <ul className={classes.navList}>
        <li>
          <Link
            className={classes.navItem}
            to="top"
            activeClass={classes.active}
            smooth={true}
            offset={-100}
            duration={500}
            spy={true}
          >
            Home
          </Link>
        </li>
        <li>
          <Link
            className={classes.navItem}
            to="background"
            activeClass={classes.active}
            smooth={true}
            offset={-100}
            duration={500}
            spy={true}
          >
            Background
          </Link>
        </li>
        <li>
          {atBottom ? (
            <Link
              className={`${classes.navItem} ${classes.showInactive}`}
              to="myWork"
              smooth={true}
              offset={-150}
              duration={500}
            >
              My Work
            </Link>
          ) : (
            <Link
              className={classes.navItem}
              to="myWork"
              activeClass={classes.active}
              smooth={true}
              offset={-150}
              duration={500}
              spy={true}
            >
              My Work
            </Link>
          )}
        </li>
        <li>
          {atBottom ? (
            <Link className={`${classes.navItem} ${classes.showActive}`} to="contact" smooth={true} duration={500}>
              Contact
            </Link>
          ) : (
            <Link className={classes.navItem} to="contact" activeClass={classes.active} smooth={true} duration={500}>
              Contact
            </Link>
          )}
        </li>
      </ul>
      <div className={classes.barsContainer}>
        <FaBars className={classes.mobileBars} onClick={navHandler} />
      </div>
      {showNav && (
        <ul className={classes.mobileNavList}>
          <li>
            <Link
              className={classes.mobileNavItem}
              to="top"
              activeClass={classes.active}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={navHandler}
              spy={true}
            >
              Home
            </Link>
          </li>
          <li>
            <Link
              className={classes.mobileNavItem}
              to="background"
              activeClass={classes.active}
              smooth={true}
              offset={-100}
              duration={500}
              onClick={navHandler}
              spy={true}
            >
              Background
            </Link>
          </li>
          <li>
            {atBottom ? (
              <Link
                className={`${classes.mobileNavItem} ${classes.showInactive}`}
                to="myWork"
                smooth={true}
                offset={-150}
                duration={500}
                onClick={navHandler}
              >
                My Work
              </Link>
            ) : (
              <Link
                className={classes.mobileNavItem}
                to="myWork"
                activeClass={classes.active}
                smooth={true}
                offset={-150}
                duration={500}
                spy={true}
                onClick={navHandler}
              >
                My Work
              </Link>
            )}
          </li>
          <li>
            {atBottom ? (
              <Link
                className={`${classes.mobileNavItem} ${classes.showActive}`}
                to="contact"
                smooth={true}
                duration={500}
                onClick={navHandler}
              >
                Contact
              </Link>
            ) : (
              <Link
                className={classes.mobileNavItem}
                to="contact"
                activeClass={classes.active}
                smooth={true}
                duration={500}
                onClick={navHandler}
              >
                Contact
              </Link>
            )}
          </li>
        </ul>
      )}
    </nav>
  );
};

export default NavBar;
