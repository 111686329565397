import React, { useState, useEffect, useRef } from "react";
import classes from "./ShowCase.module.css";

const ShowCase = () => {
  const [nameAtTop, setNameAtTop] = useState(false);
  const name = useRef();
  const initialClass = nameAtTop
    ? `${classes.initial} ${classes.show}`
    : `${classes.initial}`;

  const handleScroll = () => {
    if (name.current.getBoundingClientRect().top <= -60) {
      setNameAtTop(true);
    } else {
      setNameAtTop(false);
    }
  };
  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
  }, []);
  return (
    <div className={classes.showCase} id="top">
      <div className={initialClass}>JF</div>
      <h1 className={classes.name} ref={name}>
        Jeremiah Failla
      </h1>
      <p className={classes.title}>Web Developer</p>
    </div>
  );
};

export default ShowCase;
