import React from "react";
import "./App.css";
import ShowCase from "./components/showcase/ShowCase";
import Background from "./components/background/Background";
import NavBar from "./components/navbar/NavBar";
import MyWork from "./components/myWork/MyWork";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="app">
      <ShowCase />
      <NavBar />
      <Background />
      <MyWork />
      <Footer />
    </div>
  );
}

export default App;
