import React from "react";
import classes from "./Footer.module.css";
import { MdContactPage } from "react-icons/md";
import { BsLinkedin } from "react-icons/bs";
import { FaGithubSquare } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className={classes.footer} id="contact">
      <p>jpf405@gmail.com</p>
      <div className={classes.iconContainer}>
        <a
          href={process.env.PUBLIC_URL + "/resume/resume.pdf"}
          target="_blank"
          data-title="Resume"
        >
          <MdContactPage className={classes.icon} />
        </a>
        <a
          data-title="LinkedIn"
          href="https://www.linkedin.com/in/jeremiah-failla-70420522b/"
          target="_blank"
        >
          <BsLinkedin className={classes.icon} />
        </a>
        <a
          data-title="GitHub"
          href="https://github.com/JeremiahFailla"
          target="_blank"
        >
          <FaGithubSquare className={classes.icon} />
        </a>
      </div>
      <p>Jeremiah Failla &copy; 2023</p>
    </footer>
  );
};

export default Footer;
