import React from "react";
import classes from "./Background.module.css";
import { Link } from "react-scroll";

const Background = () => {
  return (
    <section className={classes.section} id="background">
      <div className={classes.backgroundImg}></div>
      <div className={classes.contentContainer}>
        <h1 className={classes.backgroundTitle}>Background</h1>
        <p className={classes.content}>
          Oh hello there, I see you stumbled upon my website. My name is Jeremiah and I love to build and put stuff on
          the internet. My interest in web development started back in the summer of 2020 when I bought an HTML and CSS
          course on Udemy. Since then and many YouTube tutorials later, I have fallen in love with building cool and
          practical websites. To build these websites I have come to learn and gain some experience with a lot of
          different tools and technologies such as HTML, CSS, JavaScript, Java, React, Redux, Redux toolkit, Git, SQL,
          Bootstrap, MUI, MongoDB, Firebase, and much more. In the section{" "}
          <Link className={classes.link} to="myWork" smooth={true} offset={-100} duration={500}>
            below
          </Link>{" "}
          , you will find some projects that I have created using some of the different tools and technologies I've
          learned.
        </p>
      </div>
    </section>
  );
};

export default Background;
