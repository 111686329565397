import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
  apiKey: "AIzaSyBbMIuL23v76IQzFJu0XcYLiK68m-4Z7Po",
  authDomain: "personal-site-projects-d0f7a.firebaseapp.com",
  projectId: "personal-site-projects-d0f7a",
  storageBucket: "personal-site-projects-d0f7a.appspot.com",
  messagingSenderId: "945000689484",
  appId: "1:945000689484:web:fc8f0862ba3f4e956ce9e9",
};

const app = initializeApp(firebaseConfig);

export default getFirestore();
